<template>
  <div>
    <el-row v-cus-loading="form.loading" :gutter="15" class="flex-container">
      <el-col :sm="12" class="flex-item">
        <div class="card-container">
          <div class="card-column-no-bordered">
            <div class="card">
              <div class="step">
                <div class="number">
                  <span>
                    <span class="content">1</span>
                  </span>
                </div>
                <div class="title">
                  <span>{{ $tc('dataUser') }}</span>
                </div>
              </div>
              <div class="content">
                <el-form ref="formUser" :model="form.model" :rules="form.rules">
                  <el-row :gutter="20">
                    <el-col :sm="24">
                      <el-form-item :label="$tc('email')" prop="email">
                        <el-input
                          id="id_userEmail"
                          v-model.trim="form.model.email"
                          :maxlength="128"
                          :placeholder="`${$tc('email')}, ${$tc(
                            'exampleUserEmail',
                          )}`"
                          auto-complete="off"
                          @change="onChangeEmail"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-envelope-square"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                      <el-form-item :label="$tc('firstName')" prop="firstName">
                        <el-input
                          id="id_userFirstName"
                          v-model="form.model.firstName"
                          :maxlength="64"
                          :placeholder="$tc('firstName')"
                          auto-complete="off"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-portrait"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                      <el-form-item :label="$tc('lastName')" prop="lastName">
                        <el-input
                          id="id_userLastName"
                          v-model="form.model.lastName"
                          :maxlength="64"
                          :placeholder="$tc('lastName')"
                          auto-complete="off"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-portrait"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                      <el-form-item :label="$tc('phoneCell')" prop="phone">
                        <el-input
                          id="id_phone"
                          v-model.trim="form.model.phone"
                          :controls="false"
                          :placeholder="$tc('phoneCell')"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-phone-volume"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :sm="12" class="flex-item">
        <div class="card-container">
          <div class="card-column-no-bordered">
            <div class="card">
              <div class="step">
                <div class="number">
                  <span>
                    <span class="content">2</span>
                  </span>
                </div>
                <div class="title">
                  <span>{{ $tc('dataCompany') }}</span>
                </div>
              </div>
              <div class="content">
                <el-form
                  ref="formCompany"
                  :model="form.model"
                  :rules="form.rules"
                >
                  <el-form-item
                    :label="$tc('type')"
                    :rules="form.rules.company.fields.identificationTypeId"
                    prop="company.identificationTypeId"
                  >
                    <select-identification-type
                      v-model="form.model.company.identificationTypeId"
                      name="identificationTypeId"
                      @input="onValidateIdentificationPattern($event)"
                      @set-identification-type-code="
                        form.model.company.identificationTypeCode = $event
                      "
                      @set-identification-type-name="
                        form.model.company.identificationTypeName = $event
                      "
                    ></select-identification-type>
                  </el-form-item>
                  <el-form-item
                    :label="$tc('identification')"
                    :rules="form.rules.company.fields.companyIdentification"
                    prop="company.companyIdentification"
                  >
                    <el-input
                      id="id_companyIdentificationNumber"
                      v-model.trim="form.model.company.companyIdentification"
                      name="companyIdentification"
                      :maxlength="24"
                      :placeholder="$tc('identification')"
                      auto-complete="off"
                      @change="onChangeIdentification"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon fa fa-building"
                      ></i>
                      <template slot="append">
                        -
                        {{ form.model.company.companyIdentificationCheck }}
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    :label="$tc('companyName')"
                    :rules="form.rules.company.fields.companyName"
                    prop="company.companyName"
                  >
                    <el-input
                      id="id_companyName"
                      v-model="form.model.company.companyName"
                      name="companyName"
                      :maxlength="128"
                      :placeholder="$tc('companyName')"
                      auto-complete="off"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon fa fa-building"
                      ></i>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="content" style="text-align: left">
                <el-checkbox v-model="checkedPolicy">
                  <a
                    href="https://s3.amazonaws.com/cdn.aliaddo.com/contrato-de-prestacion-de-servicios-aliaddo-v28-01-2022.docx.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Términos y condiciones</a>
                </el-checkbox>
                <br />
                <el-checkbox v-model="checkedData">
                  <a
                    href="https://aliaddo.com/privacidad/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Política de Tratamiento de Datos</a>
                </el-checkbox>
              </div>
              <div class="content" style="text-align: left">
                <el-alert
                  v-if="!checkedPolicy || !checkedData"
                  :description="$tc('checkPolicy')"
                  style="word-break: break-word"
                  :closable="false"
                  :center="false"
                  show-icon
                  type="warning"
                >
                </el-alert>
              </div>
              <div class="content">
                <div v-if="form.errors.length > 0" class="left">
                  <el-alert type="error" :title="$tc('validateForm')">
                    <ul>
                      <li v-for="(item, index) in form.errors" :key="index">
                        <b>{{ item.field ? `${$tc(item.field)}: ` : '' }}</b>{{ item.message }}
                      </li>
                    </ul>
                  </el-alert>
                </div>
                <el-button
                  v-if="
                    company.companyServiceFrequencyBilling &&
                    +company.companyServiceFrequencyBilling.length &&
                    +company.planId
                  "
                  size="mini"
                  class="btn btn-select"
                  :disabled="checkedPolicy === false || checkedData === false"
                  @click.prevent="onRegister"
                >
                  <span>
                    {{ $tc('create', 2) }}
                  </span>
                </el-button>
                <el-alert
                  v-else
                  type="error"
                  :closable="false"
                  :title="$tc('frecuencyBillingInvalid')"
                  effect="dark"
                >
                </el-alert>
                <div class="footer">
                  <span
                    style="color: var(--tag-color-text-two)"
                    :class="{ 'text-line-through': isAliaddo }"
                  >
                    <small>
                      <em> {{ $tc('yourIp') }}: {{ form.model.ipAddress }} </em>
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="$tc('add', 1)"
      :visible.sync="dialog.address.visible"
      width="45% "
      append-to-body
    >
      <form-person-address
        v-if="dialog.address.visible"
        ref="formPersonAddress"
        :item.sync="model.address"
        @created="onAddAddress"
      ></form-person-address>
    </el-dialog>
  </div>
</template>
<script>

import calculateIdentificationCheck from '@/helpers/identification-check'
import { validateForm } from '@/helpers/messenger'
import { fieldRequiredByBlur } from '@/helpers/validations'
import create from '@/views/common/create'
import dialog, { showDialog } from '@/views/common/dialog'
import form from '@/views/common/form'
import axios from 'axios'
import model from './model'
import rules from './rules'

export default {
  extends: create('companies', { model }),
  props: {
    company: { type: Object, required: true }
  },
  data() {
    return {
      form: form({ model, rules }),
      checkedPolicy: false,
      checkedData: false,
      dialog: {
        address: dialog()
      },
      list: {
        files: []
      },
      loading: {
        personAddress: false
      },
      model: {
        rut: '',
        response: {},
        userExists: {}
      }
    }
  },
  computed: {
    isAliaddo: {
      get(_this) {
        return _this.form.model.ipAddress === '186.31.27.24'
      }
    }
  },
  mounted() {
    this.getIp()

    // this.recaptcha()
  },
  methods: {
    showDialog,
    onChangeIdentification(event) {
      const _this = this
      _this.form.model.company.companyIdentificationCheck =
        calculateIdentificationCheck(
          _this.form.model.company.companyIdentification
        )
      _this.onValidateIdentificationPattern(
        _this.form.model.company.identificationTypeId
      )
    },
    onChangeEmail(event) {
      const _this = this
      if (event && +event.length > 0) {
        _this.form.loading = true
        _this.$http
          .get(`users/${event}/findByEmail`)
          .then(result => {
            _this.model.userExists = result.data
            if (result.data) {
              _this.form.model = {
                ..._this.form.model,
                ...{
                  userName: result.data.userName,
                  phone: result.data.userPhone
                }
              }
            }
          })
          .finally(() => {
            _this.form.loading = false
          })
      } else {
        _this.form.model = {
          ..._this.form.model,
          ...{
            userName: '',
            phone: ''
          }
        }
        _this.model.userExists = {}
      }
    },
    async onRegister() {
      const _this = this
      _this.form.errors = []
      const promiseFormUser = new Promise(resolve => {
        _this.$refs.formUser.validate((valid, errors) => {
          for (const attribute in errors) {
            errors[attribute].forEach(element => {
              _this.form.errors.push({
                field: element.field,
                message: element.message
              })
            })
          }
          resolve(valid)
        })
      })
      const promiseFormCompany = new Promise(resolve => {
        _this.$refs.formCompany.validate((valid, errors) => {
          for (const attribute in errors) {
            errors[attribute].forEach(element => {
              _this.form.errors.push({
                field: element.field,
                message: element.message
              })
            })
          }

          resolve(valid)
        })
      })
      Promise.all([promiseFormUser, promiseFormCompany])
        .then(isValid => {
          if (
            isValid.find(item => item === false) != null ||
            _this.checkedPolicy === false ||
            _this.checkedData === false
          ) {
            validateForm()

            return
          }
          _this.form.model = {
            ..._this.form.model,
            userFirstName: _this.form.model.firstName.trim(),
            userLastName: _this.form.model.lastName.trim(),
            userName: _this.form.model.email
              .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
              .trim()
              .slice(0, 23),
            userEmail: _this.form.model.email.trim(),
            ...{
              company: {
                ..._this.company,
                ..._this.form.model.company,
                companyName: _this.form.model.company.companyName.trim(),
                companyIdentification:
                  _this.form.model.company.companyIdentification.trim()
              }
            },
            seller: _this.$route.query.seller,
            source: _this.$route.query.source
          }
          if (
            _this.$route.query.source === 'crm' ||
            _this.$route.query.seller
          ) {
            _this.form.model.company.companyServiceSource = 'Crm'
          } else {
            _this.form.model.company.companyServiceSource = 'Site'
          }
          const formData = new FormData()
          formData.append('signup', JSON.stringify(_this.form.model))
          _this.form.loading = true
          _this.$http
            .post('companies', formData)
            .then(response => {
              setTimeout(() => {
                window.location.replace(response.body)
              }, 3000)
              _this.$message({
                type: 'success',
                message: _this.$tc('processSuccess')
              })
            })
            .catch(_ => {
              setTimeout(() => (_this.form.loading = false), 1000)
            })
            .finally(_ => {
              setTimeout(() => (_this.form.loading = false), 1000)
            })
        })
        .catch((error, data) => {
          validateForm(error.stack)
        })
    },
    onValidateIdentificationPattern(value) {
      const _this = this
      _this.form.rules.company.fields.companyIdentification = []
      if (value === '41' || value === '42' || value === '50') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[a-zA-Z0-9_\-]{4,16}$/,
            required: true,
            trigger: 'blur'
          }
        )
      } else if (value === '13') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[1-9][0-9]{3,9}$/,
            required: true,
            trigger: 'blur'
          }
        )
      } else if (value === '21') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/,
            required: true,
            trigger: 'blur'
          }
        )
      } else if (value === '12') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[1-9][0-9]{4,11}$/,
            required: true,
            trigger: 'blur'
          }
        )
      } else if (value === '31') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[1-9]\d{6,9}$/,
            required: true,
            trigger: 'blur'
          }
        )
      } else {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur
        )
      }
    },
    getIp() {
      axios
        .get('https://api.ipify.org?format=json')
        .then(response => (this.form.model.ipAddress = response.data.ip))
    }

    // async recaptcha () {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded()
    //   // Execute reCAPTCHA with action "login".
    //   await this.$recaptcha('onRegister')
    // }
  }
}
</script>
<style>
.flex-container {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.flex-item {
  padding: 10px;
  flex: 33%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/register.scss';
@import '@/assets/scss/transactions/personalize.scss';
</style>
