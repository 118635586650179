export default {
  firstName: [{ required: true, message: 'Ingresa tus nombres', trigger: 'blur' }],
  lastName: [{ required: true, message: 'Ingresa tus apellidos', trigger: 'blur' }],
  fullName: [{ required: true, message: 'Ingresa tus nombres y apellidos', trigger: 'blur' }],
  company: {
    type: 'object',
    required: true,
    options: { first: true },
    fields: {
      companyName: { required: true, message: 'Ingresa el nombre de tu empresa', trigger: 'blur' },
      identificationTypeId: { required: true, message: 'Debe seleccionar un tipo de identificación', trigger: ['blur', 'change'] },
      companyIdentification: { required: true, message: 'Ingresa número de identificación', trigger: 'blur' },
      companyStudentInstitution: { required: true, message: 'Escribe para buscar tu institución educativa o selecciona otros', trigger: 'blur' }
    }
  },
  email: [{
    type: 'email', required: true, message: 'Ingresa tu e-mail', trigger: 'blur'
  }],
  phone: [{
    required: true, pattern: /^[0-9]*$/, message: 'Ingresa el teléfono, sin espacios, unicamente números', trigger: 'blur'
  }, {
    min: 10, max: 10, message: 'El teléfono debe contener 10 números', trigger: 'blur'
  }]
}
